import React, { useEffect, Suspense, lazy } from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';

import PrivateRoute from 'components/PrivateRoute';
import PreloadProgressBar from 'components/PreloadProgressBar';
import Auth from 'services/Auth';
import { firebaseAuthenticate, checkAndReFetchFirebaseAuth } from 'services/Firebase';
import { googleTagManager } from 'services/GoogleAnalytics';
import LocalStorageService from 'services/LocalStorageService';
import './App.scss';

const DashboardPage = lazy(() => import('views/DashboardPage'));
const LoginPage = lazy(() => import('views/LoginPage'));
const ChangePasswordPage = lazy(() => import('views/ChangePasswordPage'));

export default function App() {
  useEffect(() => {
    const isIos = [
      'MacIntel',
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform);
    if (isIos) {
      const html = document.querySelector('html');
      html.classList.add('ios-polyfill');
    }
  }, []);
  const isAuth = Auth.isAuthenticated();

  useEffect(() => {
    injectStyle();
    googleTagManager();
    if (window.location.href.includes("dev.arion.aviapartner.aero") || window.location.href.includes("uat.arion.aviapartner.aero")) {
      let meta = document.createElement('meta'); 
      meta.name = 'robots'; 
      meta.content = 'noindex'; 
      document.head.appendChild(meta);
    }
  }, []);

  useEffect(() => {
    if (isAuth) {
      firebaseAuthenticate();
      checkAndReFetchFirebaseAuth();
    }
  }, [isAuth]);

  useEffect(() => {
    const storageFile = LocalStorageService.getVersionFileToken();
    const fetchVersionFile = async () => {   
      const response = await fetch(`${process.env.PUBLIC_URL}/version.json?nocache=${Math.random()}`);
      const file = await response.json();
      if (file?.token && file?.token !== storageFile) {
        LocalStorageService.setVersionFileToken(file.token);
        caches
          .keys()
          .then(keys => {
            return Promise.all(keys.map(key => caches.delete(key)));
          })
          .catch(err => {
            console.warn('unregister caches error', err);
          });
        window.location.reload(true);
      }
    };
    fetchVersionFile();
  }, []);

  return (
    <>
      <Router>
        <Suspense fallback={<PreloadProgressBar />}>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/change-password">
              <ChangePasswordPage />
            </Route>
            <PrivateRoute path="">
              <DashboardPage />
            </PrivateRoute>
          </Switch>
        </Suspense>
      </Router>
      <ToastContainer position="bottom-left" closeOnClick />
    </>
  );
}
