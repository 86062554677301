import { unregister } from 'serviceWorker';
import { api, refreshPermissions } from 'utils/API';
import { LoggingService } from './logging/LoggingService';
import ProfilePageService from './ProfileService';
import StationsService from './StationsService';
import { deleteWebPushSubscription, firebaseSignOut } from 'services/Firebase';
import LocalStorageService from 'services/LocalStorageService';

const Auth = {
  isAuthenticated() {
    const userData = LocalStorageService.getUserData();
    return !!(userData && userData.user);
  },
  changePassword(option, loginData, successCb) {
    fetch(`${api.baseURL}/${option.url}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...option.headers,
      },
      body: JSON.stringify(loginData),
    })
      .then((res) => {
        const isError = ![200, 201, 204].includes(res.status);
        successCb({ isError });
      })
      .catch((error) => {
        console.log('changePassword error', error);
      });
  },

  authenticate(loginData, successCb, errorCb) {
    fetch(api['auth'], {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginData),
    })
      .then((res) => {
        if (res.status === 401 && res.headers.get('password-expired-token')) {
          successCb({
            expiredToken: res.headers.get('password-expired-token'),
          });
        }
        if (!res.ok) {
          errorCb();
        } else {
          return res;
        }
      })
      .then((res) => res.json())
      .then(async (userData) => {
        LocalStorageService.setUserData(userData);

        const stations = await StationsService.getStations()
          .then(({ data }) => {
            return data || null;
          })
          .catch((err) => console.log('getProfileImage', err));

        const userPhoto = await ProfilePageService.getProfileImage()
          .then((res) => {
            const { data } = res;
            return (data && data.base64Value) || null;
          })
          .catch((err) => console.log('getProfileImage', err));

        await ProfilePageService.getProfileData().then(async (res) => {
          if (res && res.data && stations) {
            const warningMessageStationList = stations.reduce((acc, item) => {
              if (item.showWarningMessage) {
                acc.push(item.stationCode);
              }
              return acc;
            }, []);
            const defaultStation = await StationsService.getProfileStation(
              stations,
              res.data.defaultStationId
            );
            userData.user.email = userData.upn;
            userData.user.userPhoto = userPhoto;
            userData.station = defaultStation.stationCode;
            userData.manualServiceRegistrationComplete =
              defaultStation.manualServiceRegistrationComplete;
            userData.localTimeZone = defaultStation.timezone;
            userData.warningMessageStationList = warningMessageStationList;
            userData.countryIsoAlpha2 = defaultStation.countryIsoAlpha2;
            LocalStorageService.setUserData(userData);
          }
        });
        await refreshPermissions();
        LoggingService.sendLogs('SUCCESSFUL_LOGIN');

        successCb();
      })
      .catch((err) => console.log('authenticate error', err));
  },
  signout(cb) {
    firebaseSignOut();
    unregister();

    deleteWebPushSubscription(LocalStorageService.getWebPushSubscription())
      .then(() => localStorage.clear())
      .catch(() => {
        const token = LocalStorageService.getWebPushSubscription();

        localStorage.clear();
        sessionStorage.clear();

        if ('caches' in window) {
          caches.keys().then((keys) => {
            keys.forEach((key) => caches.delete(key));
          });
        }

        LocalStorageService.setWebPushSubscription(token);
      });

    return cb();
  },
};

export default Auth;
