export const TRANSLATIONS_EN = {
  "occasion": "occasion",
  "You don’t have permissions to take a photo": "You don’t have permissions to take a photo",
  "No internet connection": "No internet connection.",
  "Something went wrong": "Something went wrong",
  "No services available": "No services available",
  "Password must be at least 8 characters": "Password must be at least 8 characters long and contain at least 1 uppercase and 1 lowercase letter, 1 symbol, 1 digit and no whitespaces.",
  "Service registration is closed": "Service registration is closed.",
  "New password does not meet security requirements": "New password does not meet security requirements",
  "You can't remove this temporal item": "You can't remove this temporal item",
  "add occasion": "add occasion",
  "Occasion": "Occasion",
  "Optional": "Optional",
  "Flight reopened": "Flight reopened",
  "This field is mandatory": "This field is mandatory",
  "success": "success",
  "Flight registration is closed": "Flight registration is closed.",
  "validating": "validating",
  "reopen flight": "reopen flight",
  "close the flight": "close the flight",
  "finalization": "finalization",
  "approved": "approved",
  "validation": "validation",
  "failed": "failed",
  "Photo registration": "Photo registration",
  "In order to close this flight you need to provide": "In order to close this flight you need to provide mandatory details.",
  "Photo types required": "Photo types required",
  "Something wrong": "Something wrong",
  "Receive job vacancies": "Receive job vacancies",
  "Job vacancy": "Job vacancy",
  "Go to flights": "Go to flights",
  "Page not found": "Page not found",
  "Please click here to proceed to flights list": "Please click here to proceed to flights list",
  "Your session has expired. Please sign in again.": "Your session has expired. Please sign in again.",
  "Timestamps type": "Timestamps type",
  "You were signed out automatically": "You were signed out automatically. Token has expired. You can sign back in with your credentials",
  "Are you sure you want to sign out?":"Are you sure you want to sign out?",
  "is signed in": "is signed in",
  "Sign in": "Sign in",
  "Registration closed": "Registration closed",
  "Close registration": "Close registration",
  "Dummy Type": "Dummy Type",
  "Aircraft Damage": "Aircraft Damage",
  "No types here yet": "No types here yet",
  "Select type": "Select type",
  "Please briefly describe where you found the item": "Please briefly describe where you found the item. Specify room or line and row if available",
  "type": "type",
  "Describe location": "Describe location",
  "Please pick on scheme": "Please pick on scheme",
  "Cones": "Cones",
  "Chocks": "Chocks",
  "Cannot set a future time.": "Cannot set a future time.",
  "This section is used to deal with visible damage to the airplane": "This section is used to deal with visible damage to the airplane. Please document every noticeable issue before performing any services",
  "Mark location": "Mark location",
  "The end time must be later than the start time.": "The end time must be later than the start time.",
  "Future time is not allowed": "Future time is not allowed",
  "or": "or",
  "No timestamps configured for this flight type": "No timestamps configured for this flight type",
  "Set time": "Set time",
  "Confirm remove?": "Confirm remove?",
  "Change": "Change",
  "Change password?": "Change password?",
  "My profile": "My profile",
  "Don’t use phone while driving!": "Don’t use phone while driving!",
  "Destination": "Destination",
  "Origin": "Origin",
  "filter group not found": "filter group not found",
  "Date should not be before start date": "Date should not be before start date",
  "Could not save data, please try again later": "Could not save data, please try again later",
  "The current password you've entered is incorrect": "The current password you've entered is incorrect. Please double-check and try again.",
  "no user data": "no user data",
  "Helpdesk": "Helpdesk",
  "SENT": "SENT",
  "DENIED": "DENIED",
  "DECLINED": "DECLINED",
  "CONFIRMED": "CONFIRMED",
  "ACCEPTED": "ACCEPTED",
  "Set to now": "Set to now",
  "Set": "Set",
  "Write your comment here, then press the green check mark": "Write your comment here, then press the green check mark",
  "write new comment": "write new comment",
  "Short remark": "Short remark",
  "Filter group has not been updated": "Filter group has not been updated",
  "Save service registrations problem": "Save service registrations problem",
  "and/or": "and/or",
  "Input a brief description here": "Input a brief description here",
  "Right Tail Wing": "Right Tail Wing",
  "Left Tail Wing": "Left Tail Wing",
  "Tail": "Tail",
  "Body": "Body",
  "Neck": "Neck",
  "Nose": "Nose",
  "Right Engine": "Right Engine",
  "Left Engine": "Left Engine",
  "Right Wing": "Right Wing",
  "Left Wing": "Left Wing",
  "next": "next",
  "Lost item": "Lost item",
  "Damage": "Damage",
  "Filter group": "Filter group",
  "Archived": "Archived",
  "Message archived": "Message archived",
  "Message unarchived": "Message unarchived",
  "No": "No",
  "Yes": "Yes",
  "to": "to",
  "Date range": "Date range",
  "No flight details information": "No flight details information",
  "No messages yet": "No messages yet",
  "messages yet": "messages yet",
  "Flight not found": "Flight not found",
  "from": "from",
  "From": "From",
  "Until": "Until",
  "Search": "Search",
  "registration complete": "registration complete",
  "Flight number/registration": "Flight number/registration",
  "Attachment": "Attachment",
  "Airline": "Airline",
  "Username": "Username",
  "Password": "Password",
  "Log in": "Log in",
  "Open file": "Open file",
  "Unavailable": "Unavailable",
  "Read & Sign": "Read&Sign",
  "Available": "Available",
  "Sign for understanding": "Sign for understanding",
  "You can check your APPI code on Sunrise portal.": "You can check your APPI code on Sunrise portal.",
  "We strongly recommend saving it in your smartphone phonebook": "We strongly recommend saving it in your smartphone phonebook so that you always have the APPI code at hand. To save the code, go to your Sunrise profile and scan the QR code with your smartphone.",
  "The APPI code is required when resetting your Aviapartner": "The APPI code is required when resetting your Aviapartner account password in Sunrise or through the Aviapartner helpdesk. You can  always find your APPI code in your profile in Sunrise.",
  "Extra shift request confirmed": "Extra shift request confirmed",
  "This request is not valid anymore": "This request is not valid anymore",
  "This message is marked as urgent": "This message is marked as urgent",
  "A new password should be different than the current one": "A new password should be different than the current one",
  "Your password has expired. Please compose a new password and log in again.": "Your password has expired. Please compose a new password and log in again.",
  "Your password and confirmation password do not match": "Your password and confirmation password do not match.",
  "Your password must contain at least eight characters, at least one number and both lower and uppercase letters. ": "Your password must contain at least eight characters, at least one number and both lower and uppercase letters. ",
  "The username or password you've entered is incorrect": "The username or password you've entered is incorrect. Please  double-check and try again.",
  "Log out": "Log out",
  "Change password": "Change password",
  "Confirm new password": "Confirm new password",
  "New password": "New password",
  "Current password": "Current password",
  "is logged in": "is logged in",
  "Proceed to login": "Proceed to login",
  "Your password has been changed successfully!": "Your password has been changed successfully!",
  "Pick if its a damaged part or lost item": "Pick if its a damaged part or lost item",
  "Pin on schema": "Pin on schema",
  "Specify where did you found the item": "Specify where did you found the item",
  "Include nearby area": "Include nearby area",
  "Add other location": "Add other location",
  "Add row": "Add row",
  "Add line": "Add line",
  "Skip this step": "Skip this step",
  "Close": "Close",
  "Take a photo": "Take a photo",
  "Add description": "Add description",
  "Add location where item was found": "Add location where item was found",
  "Add storage location": "Add storage location",
  "Briefly describe where the item will be stored for picking up.": "Briefly describe where the item will be stored for picking up.",
  "area": "area",
  "save": "save",
  "FirebaseError: A document cannot be written because it exceeds the  maximum size allowed":  "FirebaseError: A document cannot be written because it exceeds the  maximum size allowed",
  "Photos": "Photos",
  "Messages": "Messages",
  "Remove": "Remove",
  "Are you sure you want to remove photo?": "Are you sure you want to remove photo?",
  "Comment": "Comment",
  "Included nearby area": "Included nearby area",
  "Tap on area where damaged part is located": "Tap on area where damaged part is located",
  "Conditional services": "Conditional services",
  'SYSTEM SETTINGS': 'SYSTEM SETTINGS',
  'Flights': 'Flights',
  'Weather': 'Weather',
  'Language': 'Language',
  'Receive Extra shift requests': 'Receive Extra shift requests',
  'Default station': 'Default station',
  'Profile data wasn\'t updated!': 'Profile data wasn\'t updated!',
  'Station': 'Station',
  'Sign out': 'Sign out',
  'Cancel': 'Cancel',
  'Stations': 'Stations',
  'Profile': 'Profile',
  'Next 5 days': 'Next 5 days',
  'Today': 'Today',
  'TODAY': 'TODAY',
  'Humidity': 'Humidity',
  'Wind': 'Wind',
  'Precipitation': 'Precipitation',
  'Pressure': 'Pressure',
  'Clouds': 'Clouds',
  'Visibility': 'Visibility',
  'Sunrise': 'Sunrise',
  'Flight details': 'Flight details',
  'General info': 'General info',
  'Parking': 'Parking',
  'Runway': 'Runway',
  'Gate': 'Gate',
  'Aircraft type': 'Aircraft type',
  'Operation flight number': 'Operation flight number',
  'Seats': 'Seats',
  'Seat': 'Seat',
  'Flight type': 'Flight type',
  'Registration': 'Registration',
  'Call sign': 'Call sign',
  'Crew signature': 'Crew signature',
  'Extra services': 'Extra services',
  'extra service': 'extra service',
  'Comments': 'Comments',
  'Service registration': 'Service registration',
  'All': 'All',
  "Signed": "Signed",
  "Internal communication": "Internal communication",
  "Extra shift request": "Extra shift request",
  "Extra Shift Request": "Extra shift request",
  "Service info saved": "Service info saved",
  "Time registration": "Time registration",
  "No instructions found": "No instructions found",
  "Instructions": "Instructions",
  "NOW": "NOW",
  "Sunset": "Sunset",
  "The app has been updated": "The app has been updated",
  "End time should not be before start time": "End time should not be before start time",
  "Start Time": "Start Time",
  "Start": "Start",
  "End Time": "End Time",
  "End": "End",
  "Tomorrow": "Tomorrow",
  "Yesterday": "Yesterday",
  "No photo available": "No photo available",
  "Show Photo": "Show Photo",
  "Walkaround Arrival Checklist": "Walkaround Arrival Checklist",
  "Inspections": "Inspections",
  "Walkaround Pre-Departure Checklist": "Walkaround Pre-Departure Checklist",
  "Completed": "Completed",
  "Not completed": "Not completed",
  "Visual checks must be performed BEFORE GSE positioning": "Visual checks must be performed BEFORE GSE positioning",
  "Visual checks must be performed AFTER GSE removal": "Visual checks must be performed AFTER GSE removal",
  "Report any irregularities found upon arrival": "Report any irregularities found upon arrival",
  "Report any other irregularities": "Report any other irregularities",
  "If damage is found, mark it on the sketch": "If damage is found, mark it on the sketch",
  "Checks on easily visible parts of the aircraft from the ground": "Checks on easily visible parts of the aircraft from the ground. No obvious damage or anomalies to:",
  "Please make sure you attached photos of irregularities": "Please make sure you attached photos of irregularities",
  "Add description if there is a irregularity!": "Add description if there is a irregularity!",
  "Sign form below to complete it": "Sign form below to complete it:",
  "This form is signed": "This form is signed",
  "Reopen": "Reopen",
  "Add note": "Add note...",
  "Hold Inspection on Arrival": "Hold Inspection on Arrival",
  "Hold Inspection on Departure": "Hold Inspection on Departure",
  "Flight information registration": "Flight information registration",
  "Arrival MVT sent successfully": "Arrival MVT sent successfully",
  "Arrival MVT sending": "Arrival MVT sending",
  "Arrival MVT not sent yet": "Arrival MVT not sent yet",
  "Cannot send Arrival MVT": "Cannot send Arrival MVT. Addresses (SITA and/or email) for this flight are not configured in Sunrise.",
  "SEND ARRIVAL MVT": "SEND ARRIVAL MVT",
  "SEND CORRECTION ARRIVAL MVT": "SEND CORRECTION ARRIVAL MVT",
  "In order to send Arrival MVT you need to provide mandatory details": "In order to send Arrival MVT you need to provide mandatory details",
  "Send the Arrival MVT message to recipients:": "Send the Arrival MVT message to recipients:",
  "the MVT will be sent": "After clicking 'CONFIRM', the MVT will be sent. It can be re-sent if there is a change in ALDT or/and AIBT.",
  "the correction MVT will be sent": "After clicking 'CONFIRM', the correction MVT will be sent. It can be re-sent if there is a change in ALDT or/and AIBT.",
  "Invalid ALDT/AIBT value or Arrival MVT with such ALDT and AIBT values already exist": "Invalid ALDT/AIBT value or Arrival MVT with such ALDT and AIBT values already exist",
  "Cannot create MVT message: missing flight information": "Cannot create MVT message: missing flight information",
  "MVT History": "MVT History",
  "Timeline": "Timeline",
  "No photos here yet": "No photos here yet",
  "Safety Ramp Checklist": "Safety Ramp Checklist",
  "required to close the flight": "required to close the flight",
  "Forms": "Forms",
  "Message state": "Message state",
  "Locked application": "Locked application",
  "In order to open 'Calendar', read and process recent Read & Sign messages.": "In order to open 'Calendar', read and process recent Read & Sign messages.",
  "Number of unread R&S:": "Number of unread R&S:",
  "Go to Unread R&S": "Go to Unread R&S",
  "The shift is already confirmed to": "The shift is already confirmed to",
  "employees": "employees",
  "Name Surname": "Name Surname",
  "Start anti-icing": "Start anti-icing",
  "Stop anti-icing": "Stop anti-icing",
  "Deicing Service Registration": "Deicing Service Registration",
  "Anti-icing": "Anti-icing",
  "Type": "Type",
  "Product type": "Product type",
  "Product name": "Product name",
  "Mix": "Mix",
  "Temperature (C°)": "Temperature (C°)",
  "Quantity (liter)": "Quantity (liter)",
  "Add deicing/anti-icing": "Add deicing/anti-icing",
  "Agent’s name:": "Agent’s name:",
  "Agent’s signature:": "Agent’s signature:",
  "Client’s signature:": "Client’s signature:",
  "Load Verification Cross-Check Form": "Load Verification Cross-Check Form",
  "You are offline": "You are offline.",
  "The planning shown below might not be up-to-date": "The planning shown below might not be up-to-date.",
  "Read": "Read",
  "Unread": "Unread",
  "The elapsed time between": "The elapsed time between ",
  "and": " and ",
  "exceeds the allowed limit for this flight": " exceeds the allowed limit for this flight.",
  "Cannot be before": "Cannot be before ",
  "Cannot be after": "Cannot be after ",
  "time": " time.",
  "Required": "Required",
  "Set date": "Set date",
  "additional services": "Additional services",
  "Day": "Day",
  "Month": "Month",
  "Year": "Year",
};
